import React from 'react'
import styled, { injectGlobal } from 'styled-components'

injectGlobal`
    html,body {
        margin: 0;
        padding: 0;
        font-family: -apple-system,BlinkMacSystemFont,"Helvetica Neue","Segoe UI",Oxygen,Ubuntu,Cantarell,"Open Sans",sans-serif;
    }
    * {
        box-sizing: border-box;
    }
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.main`
  display: block;
  background: #fff;
  z-index: 2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  padding: 45px 20px 45px 20px;
  width: 456px;
  position: absolute;
`

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Box>{children}</Box>
    </Wrapper>
  )
}
